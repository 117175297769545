<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>Company Logs</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field
          class="btn btn-color btn-border btn-border-color"
          outlined
          dense
          prepend-inner-icon="mdi-magnify"
          placeholder="Search"
        >
        </v-text-field>
      </div>
    </div>
    <div class="section-action">
      <v-row>
        <v-col cols="4" md="3" lg="3">
          <label class="label-input">{{
            $t("ReportScanInOut.startSearch")
          }}</label>
          <DatePicker
            class="date-time"
            style="width: 100%"
            type="date"
            valueType="format"
            slot="activator"
            :append-to-body="true"
            name="founding_date"
            v-model="start_date"
            @change="handleEventFilterDate($event, 'start')"
          ></DatePicker>
        </v-col>
        <v-col cols="4" md="3" lg="3">
          <label class="label-input">{{
            $t("ReportScanInOut.endSearch")
          }}</label>
          <DatePicker
            class="date-time"
            style="width: 100%"
            type="date"
            valueType="format"
            slot="activator"
            :append-to-body="true"
            v-model="end_date"
            @change="handleEventFilterDate($event, 'end')"
            name="founding_date"
          >
          </DatePicker>
        </v-col>
      </v-row>
    </div>

    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view">
          <tr>
            <th>By</th>
            <th>Doing</th>
            <th>action</th>
            <th>at</th>
          </tr>
          <tbody>
            <tr v-for="item in CompanyLogs" :key="item.index">
              <td>
                <div class="company-log">
                  <div class="log-image">
                    <div class="log-img">
                      <div class="log-logo">
                        <i class="fas fa-user"></i>
                      </div>
                    </div>
                  </div>
                  <span class="ml-5">{{ item.user_name }}</span>
                </div>
              </td>
              <td>
                <p class="text-danger">
                  <span class="text-primary">{{ item.log_name }}</span>
                </p>
              </td>
              <td>
                <div v-if="item.properties.attributes">
                  <span style="white-space: normal">{{
                    item.properties.attributes
                  }}</span>
                </div>
                <div v-else>
                  <span class="text-danger"> {{ item.description }}</span>
                </div>
              </td>
              <td class="text-danger">{{ item.created }}</td>
            </tr>
          </tbody>
        </table>

        <Pagination
          class="mt-5 mb-5"
          v-if="pagination.total_pages > 1"
          :pagination="pagination"
          :offset="offset"
          @paginate="fetchCompanyLogs"
        >
        </Pagination>
      </div>
    </div>
    <Loading v-if="isLoading" />
  </div>
</template>

<script>
import moment from "moment";
import Pagination from "@/components/Paginate/Pagination";
import Loading from "@/components/Loading";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  components: {
    Pagination,
    Loading,
    DatePicker,
  },

  data() {
    return {
      offset: 10,
      pagination: {
        current_page: 1,
      },
      per_page: 10,
      CompanyLogs: [],
      isLoading: false,
      start_date: new Date().toISOString().slice(0, 10),
      end_date: new Date().toISOString().slice(0, 10),
    };
  },
  methods: {
    handleEventFilterDate(evt, status) {
      this.pagination.current_page = 1;
      this.fetchCompanyLogs();
    },
    fetchCompanyLogs() {
      this.isLoading = true;
      this.$axios
        .get(`company/logs`, {
          params: {
            start_date: this.start_date,
            end_date: this.end_date,
            page: this.pagination.current_page,
            per_page: this.per_page,
          },
        })
        .then((res) => {
          this.isLoading = false;
          if (res.data.code === 200) {
            this.CompanyLogs = res.data.data.data;
            this.pagination = res.data.data.pagination;

            if (!this.CompanyLogs.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
        });
    },
  },
  created() {
    this.fetchCompanyLogs();
    let date = new Date();
    let firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
    this.start_date = moment(firstDate).format("YYYY-MM-DD");
  },
};
</script>

<style scoped lang="scss">
.company-log {
  display: flex;
  align-items: center;
  position: relative;

  .log-image i {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #999999;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 24px;
  }
}
</style>
