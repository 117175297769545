<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("export_excel.title") }}</h1>
      </div>
    </div><br>
    <div class="section-action">
      <v-row>
        <v-col cols="4" md="3" lg="3">
          <!-- <label class="label-input">{{
              $t("ReportScanInOut.startSearch")
            }}</label> -->
          <DatePicker class="date-time" style="width: 100%" type="date" valueType="format" slot="activator"
            :append-to-body="true" name="founding_date" v-model="start_date"></DatePicker>
        </v-col>
        <v-col cols="4" md="3" lg="3">
          <!-- <label class="label-input">{{
              $t("ReportScanInOut.endSearch")
            }}</label> -->
          <DatePicker class="date-time" style="width: 100%" type="date" valueType="format" slot="activator"
            :append-to-body="true" v-model="end_date" name="founding_date">
          </DatePicker>
        </v-col>
        <v-col cols="4" md="1" lg="1">
          <v-btn color="primary" class="white--text" @click="fetchTrackingExport()">
            <v-icon left dark>
              mdi-magnify
            </v-icon>

          </v-btn>
        </v-col>
      </v-row>
    </div>

    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view">
          <tr>
            <th>#</th>
            <th>{{ $t("export_excel.talbe.file_name") }}</th>
            <th>Export !</th>
            <th>{{ $t("export_excel.talbe.date_time") }}</th>
            <th>{{ $t("export_excel.talbe.file") }}</th>
          </tr>
          <tbody>
            <tr v-for="(item, index) in TrackingExport" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ item.title }}</td>
              <td>{{ item.type }}</td>
              <td>{{ item.created_at }}</td>
              <td width="150">
                <div v-if="item.status == 'success'">
                  <div v-if="item.files.length > 0">
                    <div v-for="(file_item, k) in item.files" :key="k">
                      <v-btn :loading="download_loading" :disabled="download_loading" small color="blue-grey"
                        class="ma-2 white--text lfont" @click="download_excel_file(file_item.file_name)">
                        <v-icon left dark>
                          mdi-cloud-download
                        </v-icon>
                        {{ $t("export_excel.talbe.download") }}
                      </v-btn>
                      <v-btn :loading="delete_loading" :disabled="delete_loading" small color="red"
                        class="ma-2 white--text lfont" @click="delete_excel_file(item.id)">
                        <v-icon left dark>
                          mdi-trash-can
                        </v-icon>
                        {{ $t("export_excel.talbe.delete") }}
                      </v-btn>
                    </div>
                  </div>
                  <div v-else>
                    <v-btn :loading="download_loading" :disabled="download_loading" small color="blue-grey"
                      class="ma-2 white--text lfont" @click="download_excel_file(item.title)">
                      <v-icon left dark>
                        mdi-cloud-download
                      </v-icon>
                      {{ $t("export_excel.talbe.download") }}
                    </v-btn>
                    <v-btn :loading="delete_loading" :disabled="delete_loading" small color="red"
                      class="ma-2 white--text lfont" @click="delete_excel_file(item.id)">
                      <v-icon left dark>
                        mdi-trash-can
                      </v-icon>
                      {{ $t("export_excel.talbe.delete") }}
                    </v-btn>
                  </div>

                </div>
                <div v-else-if="item.status == 'fail'">
                  <span style="color: red;">Fail</span>
                  <v-btn :loading="delete_loading" :disabled="delete_loading" small color="red"
                    class="ma-2 white--text lfont" @click="delete_excel_file(item.id)">
                    <v-icon left dark>
                      mdi-trash-can
                    </v-icon>
                    {{ $t("export_excel.talbe.delete") }}
                  </v-btn>
                </div>
                <div v-else>
                  <img src="@/assets/loading-gif.gif" alt="" width="30" /><br>
                  <div v-if="item.files.length > 0">
                    <div v-for="(file_item, k) in item.files" :key="k">
                      <v-btn :loading="download_loading" :disabled="download_loading" small color="blue-grey"
                        class="ma-2 white--text lfont" @click="download_excel_file(file_item.file_name)">
                        <v-icon left dark>
                          mdi-cloud-download
                        </v-icon>
                        {{ $t("export_excel.talbe.download") }}
                      </v-btn>
                      <v-btn :loading="delete_loading" :disabled="delete_loading" small color="red"
                        class="ma-2 white--text lfont" @click="delete_excel_file(item.id)">
                        <v-icon left dark>
                          mdi-trash-can
                        </v-icon>
                        {{ $t("export_excel.talbe.delete") }}
                      </v-btn>
                    </div>
                  </div>
                  <div v-else>
                    <v-btn :loading="delete_loading" :disabled="delete_loading" small color="red"
                      class="ma-2 white--text lfont" @click="delete_excel_file(item.id)">
                      <v-icon left dark>
                        mdi-trash-can
                      </v-icon>
                      {{ $t("export_excel.talbe.delete") }}
                    </v-btn>
                  </div>
                </div>

              </td>
            </tr>
          </tbody>
        </table>

        <Pagination class="mt-5 mb-5" v-if="pagination.total_pages > 1" :pagination="pagination" :offset="offset"
          @paginate="fetchTrackingExport">
        </Pagination>
      </div>
    </div>
    <Loading v-if="isLoading" />
  </div>
</template>

<script>
import moment from "moment";
import Pagination from "@/components/Paginate/Pagination";
import Loading from "@/components/Loading";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import io from "socket.io-client";

export default {
  components: {
    Pagination,
    Loading,
    DatePicker,
  },

  data() {
    return {
      download_loading: false,
      delete_loading: false,
      offset: 10,
      pagination: {
        current_page: 1,
      },
      per_page: 10,
      TrackingExport: [],
      isLoading: false,
      start_date: new Date().toISOString().slice(0, 10),
      end_date: new Date().toISOString().slice(0, 10),
    };
  },
  methods: {
    download_excel_file(fileName) {
      this.download_loading = true;
      const item = {};
      this.$axios
        .post(`company/tracking/export/excel/${fileName}`, item, {
          responseType: "blob",
        })
        .then((res) => {
          const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
          const fileLink = document.createElement("a");
          fileLink.href = fileUrl;
          fileLink.setAttribute("download", fileName + ".xlsx");
          document.body.appendChild(fileLink);
          fileLink.click();
          document.body.removeChild(fileLink);
          this.download_loading = false;
        })
        .catch((error) => {
          this.download_loading = false;
        });
    },
    delete_excel_file(id) {
      this.delete_loading = true;
      this.$axios
        .delete(`company/tracking/export/excel/${id}`)
        .then((res) => {
          this.delete_loading = false;
          this.fetchTrackingExport();
        })
        .catch((error) => {
          this.delete_loading = false;
        });
    },
    handleEventFilterDate(evt, status) {
      this.pagination.current_page = 1;
      this.fetchTrackingExport();
    },
    fetchTrackingExport() {
      this.isLoading = true;
      this.$axios
        .get(`company/tracking/export/excel`, {
          params: {
            start_date: this.start_date,
            end_date: this.end_date,
            page: this.pagination.current_page,
            per_page: this.per_page,
          },
        })
        .then((res) => {
          this.isLoading = false;
          if (res.data.code === 200) {
            this.TrackingExport = res.data.data.data;
            this.pagination = res.data.data.pagination;

            if (!this.TrackingExport.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
        });
    },
  },
  mounted: function () {
    const socket_url = process.env.VUE_APP_SOCKET_URL;
    this.socket = io(socket_url);
    this.socket.on("message", (res) => {
      const my_info = JSON.parse(localStorage.getItem('synergy_user'));
      console.log(res.tracking_id + ' = ' + my_info.company_id);
      if (res.tracking_id == my_info.company_id) {
        this.fetchTrackingExport();
      }
    });
  },
  created() {
    this.fetchTrackingExport();
    let date = new Date();
    let firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
    this.start_date = moment(firstDate).format("YYYY-MM-DD");
  },
};
</script>

<style scoped lang="scss">
.company-log {
  display: flex;
  align-items: center;
  position: relative;

  .log-image i {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #999999;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 24px;
  }
}
</style>